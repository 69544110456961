<template>
  <div>
    <loading :active.sync="isLoading"></loading>
    <section class="relative overflow-hidden" style="height: 100vh">
      <div class="login-screeen-shape-1"></div>
      <div class="login-screen-header"></div>
      <div class="login-screen-footer"></div>
    </section>
    <section class="login-container">
      <!-- start:component -->
      <div class="flex flex-col items-center justify-center ">
        <img
          src="@/assets/otoride/logo/otoride-logo-v2-1x.png"
          alt="OTORide Logo"
        />
        <div class="mt-5 login-area">
          <div class="w-full px-12 my-5">
            <h1
              class="mt-16 mb-10 text-lg font-bold text-center sm:text-xl md:text-2xl"
              style="color: #505458"
            >
              Update Your Password
            </h1>
            <div class="h-6 text-center text-red-500">
              <transition name="fade">
                <div
                  class="text-center text-red-500"
                  v-if="reqStatus === 'failed'"
                >
                  Invalid Credentials
                </div>
              </transition>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submit)">
                <ValidationProvider
                  name="old_password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <password-field v-model="oldPassword" name="Old Password" />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>
                <ValidationProvider
                  name="new_password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <password-field v-model="newPassword" name="New Password" />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>

                <ValidationProvider
                  name="confirm_password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <password-field
                    v-model="confirmPassword"
                    name="Confirm Password"
                  />
                  <input-error-item :message="errors[0]" />
                </ValidationProvider>
                <div class="pb-6 mt-6">
                  <t-button class="w-full" type="submit">
                    Update Password
                  </t-button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <!-- end:component -->
    </section>
  </div>
</template>

<script>
import PasswordField from '@/components/form/PasswordField'

export default {
  components: {
    PasswordField,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  computed: {
    userId() {
      return this.$store.getters['auth/userInfo'].id || null
    },
    isLoggedIn: function() {
      return this.$store.getters['auth/status']
    },
    reqStatus: function() {
      return this.$store.getters['auth/reqStatus']
    },
    isLoading: function() {
      return this.$store.getters['auth/reqStatus'] === 'processing'
        ? true
        : false
    },
  },
  methods: {
    async submit() {
      console.log('passhit')
      try {
        const payload = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          confirm_new_password: this.confirmPassword,
          userId: this.userId,
        }
        await this.$store.dispatch('auth/updatePassword', payload)
        this.$router.replace('/')
      } catch (error) {
        console.log('Update request failed')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$breakpoint-sm: 640px;

$login-area-width-mobile: 330px;
$login-area-width-desktop: 440px;

$login-area-height: 470px;

$logo-width: 223px;
$logo-height: 72px;

.login-container {
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 50%;
  left: 50%;
  margin-top: -(($login-area-height + $logo-height) / 2);
  margin-left: -($login-area-width-mobile / 2);
  @media (min-width: $breakpoint-sm) {
    margin-top: -(($login-area-height + $logo-height) / 2);
    margin-left: -($login-area-width-desktop / 2);
  }
}

.login-area {
  width: $login-area-width-mobile;
  border-radius: 0.375rem;
  box-shadow: 0 9px 25px 0 rgba(43, 61, 67, 0.11);
  background-color: #ffffff;
  z-index: 100;
  @media (min-width: 640px) {
    width: $login-area-width-desktop;
  }
}

.login-logo {
  width: $logo-width;
  height: $logo-height;
  object-fit: contain;
  z-index: 100;
}

.login-screen-header {
  background-image: url('~@/assets/otoride/auth/auth-header-v1-1x.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  height: 60vh;
}

.login-screeen-shape-1 {
  position: absolute;
  border: solid 38px #83ff00;
  right: -58px;
  top: -45px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.login-screen-footer {
  height: 40vh;
  width: 100%;
  // border: 1px solid red;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-bottom: 20rem;
  // object-fit: contain;
  background-size: cover;
  background-image: url('~@/assets/otoride/auth/auth-footer-v2-1x.png');
}
</style>
