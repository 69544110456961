var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_vm._m(0),_c('section',{staticClass:"login-container"},[_c('div',{staticClass:"flex flex-col items-center justify-center "},[_c('img',{attrs:{"src":require("@/assets/otoride/logo/otoride-logo-v2-1x.png"),"alt":"OTORide Logo"}}),_c('div',{staticClass:"mt-5 login-area"},[_c('div',{staticClass:"w-full px-12 my-5"},[_c('h1',{staticClass:"mt-16 mb-10 text-lg font-bold text-center sm:text-xl md:text-2xl",staticStyle:{"color":"#505458"}},[_vm._v(" Update Your Password ")]),_c('div',{staticClass:"h-6 text-center text-red-500"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.reqStatus === 'failed')?_c('div',{staticClass:"text-center text-red-500"},[_vm._v(" Invalid Credentials ")]):_vm._e()])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"name":"old_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"name":"Old Password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"new_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"name":"New Password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"name":"Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)}),_c('div',{staticClass:"pb-6 mt-6"},[_c('t-button',{staticClass:"w-full",attrs:{"type":"submit"}},[_vm._v(" Update Password ")])],1)],1)]}}])})],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"relative overflow-hidden",staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"login-screeen-shape-1"}),_c('div',{staticClass:"login-screen-header"}),_c('div',{staticClass:"login-screen-footer"})])}]

export { render, staticRenderFns }